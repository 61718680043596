<template>
  <div>

    <el-card>
      <el-form :model="apiForm"
               :rules="rules"
               ref="apiForm"
               label-width="100px"
               class="demo-apiForm">

        <el-form-item label="接口信息"
                      prop="method">
          <el-select v-model="apiForm.method"
                     style="width: 100px">
            <el-option v-for="method in methodList"
                       :key="method.key"
                       :label="method.value"
                       :value="method.value"></el-option>
          </el-select>
          <el-input v-model="apiForm.api_path"
                    style="width: 400px;"></el-input>
          <el-button type="primary"
                     style="margin-left: 30px; width: 97px;"
                     @click="sendApiRequest()">发 送</el-button>
          <el-button @click="saveApiInfo()">保存</el-button>
        </el-form-item>
        <el-col :span="4">
          <el-form-item label="运行环境"
                        prop="env_name">
            <el-select v-model="apiForm.env_name"
                       placeholder="请选择活动区域">
              <el-option label="core-au"
                         value="core-au"></el-option>
              <el-option label="beaver"
                         value="beaver"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="项目地址"
                        prop="obj">
            <el-select v-model="apiForm.obj"
                       placeholder="请选择活动区域">
              <el-option label="SWS"
                         value="sws"></el-option>
              <el-option label="ADMIN"
                         value="admin"></el-option>
              <el-option label="CP端"
                         value="cp"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form>
      <el-card>
        <el-row>
          <el-col :span="16">
            <el-form>
              <el-tabs v-model="activeName"
                       @tab-click="tabClick">

                <el-tab-pane label="params"
                             name="first">
                  <div style="margin-bottom: 10px; height: 28px">Query参数</div>
                  <el-card style="height: 340px">
                    <el-table :data="params_data"
                              stripe
                              height="280px">
                      <el-table-column prop="key"
                                       label="key">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.key"
                                    placeholder="key"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="value"
                                       label="value">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.value"
                                    placeholder="value"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="data_type"
                                       label="type"
                                       width="120">
                        <template slot-scope="scope">

                          <el-select v-model="scope.row.data_type">
                            <el-option v-for="item in data_tpye_list"
                                       :key="item.key"
                                       :label="item.value"
                                       :value="item.key">
                            </el-option>
                          </el-select>

                        </template>
                      </el-table-column>
                      <el-table-column prop="operate"
                                       label="操作"
                                       width="80">
                        <template slot-scope="scope">
                          <el-button size="mini"
                                     type="danger"
                                     icon="el-icon-delete"
                                     @click="deleteRow(scope.$index, 'params_data')">
                          </el-button>

                        </template>
                      </el-table-column>
                    </el-table>
                    <div>
                      <el-button type="primary"
                                 icon="el-icon-plus"
                                 size="mini"
                                 style="width: 100%"
                                 @click="addRow('params_data')">新增一行
                      </el-button>
                    </div>
                  </el-card>
                </el-tab-pane>
                <el-tab-pane label="body"
                             name="second">
                  <div style="margin-bottom: 10px;">
                    <el-radio-group v-model="radio"
                                    @input="radio_change">
                      <el-radio :label="1"
                                border
                                size="mini">None</el-radio>
                      <el-radio :label="2"
                                border
                                size="mini">Form-data</el-radio>
                      <el-radio :label="3"
                                border
                                size="mini">Json</el-radio>
                    </el-radio-group>

                  </div>
                  <el-card v-if="radio_name == 1"
                           style="height: 340px">
                    <div style="height: 300px;"><el-empty description="暂无传参"></el-empty>
                    </div>
                  </el-card>
                  <el-card v-if="radio_name == 3"
                           style="height: 340px">
                    <div style="height: 300px">
                      <el-input type="textarea"
                                :rows="14"
                                placeholder="请输入Json格式"
                                v-model.trim="json_data"
                                @blur="input_json">
                      </el-input>
                    </div>
                  </el-card>
                  <el-card v-if="radio_name == 2"
                           style="height: 340px">
                    <el-table :data="body_data"
                              stripe
                              height="280px">
                      <el-table-column prop="key"
                                       label="key">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.key"
                                    placeholder="key"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="value"
                                       label="value">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.value"
                                    placeholder="value"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="data_type"
                                       label="type"
                                       width="120">
                        <template slot-scope="scope">

                          <el-select v-model="scope.row.data_type">
                            <el-option v-for="item in data_tpye_list"
                                       :key="item.key"
                                       :label="item.value"
                                       :value="item.key">
                            </el-option>
                          </el-select>

                        </template>
                      </el-table-column>
                      <el-table-column prop="operate"
                                       label="操作"
                                       width="80">
                        <template slot-scope="scope">
                          <el-button size="mini"
                                     type="danger"
                                     icon="el-icon-delete"
                                     @click="deleteRow(scope.$index, 'body_data')">
                          </el-button>

                        </template>
                      </el-table-column>
                    </el-table>
                    <div>
                      <el-button type="primary"
                                 icon="el-icon-plus"
                                 size="mini"
                                 style="width: 100%"
                                 @click="addRow('body_data')">新增一行
                      </el-button>
                    </div>
                  </el-card>
                </el-tab-pane>
                <el-tab-pane label="headers"
                             name="third">
                  <div style="margin-bottom: 10px; height: 28px">Headers</div>
                  <el-card style="height: 340px">
                    <el-table :data="headers_data"
                              stripe
                              height="280px">
                      <el-table-column prop="key"
                                       label="key">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.key"
                                    placeholder="key"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="value"
                                       label="value">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.value"
                                    placeholder="value"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="data_type"
                                       label="type"
                                       width="120">
                        <template slot-scope="scope">

                          <el-select v-model="scope.row.data_type">
                            <el-option v-for="item in data_tpye_list"
                                       :key="item.key"
                                       :label="item.value"
                                       :value="item.key">
                            </el-option>
                          </el-select>

                        </template>
                      </el-table-column>
                      <el-table-column prop="operate"
                                       label="操作"
                                       width="80">
                        <template slot-scope="scope">
                          <el-button size="mini"
                                     type="danger"
                                     icon="el-icon-delete"
                                     @click="deleteRow(scope.$index, 'headers_data')">
                          </el-button>

                        </template>
                      </el-table-column>
                    </el-table>
                    <div>
                      <el-button type="primary"
                                 icon="el-icon-plus"
                                 size="mini"
                                 style="width: 100%"
                                 @click="addRow('headers_data')">新增一行
                      </el-button>
                    </div>
                  </el-card>
                </el-tab-pane>
                <el-tab-pane label="前置操作"
                             name="fourth">
                  <div style="margin-bottom: 10px; height: 28px">前置操作</div>
                  <el-card style="height: 340px">
                    <el-table :data="pre_data"
                              stripe
                              height="280px">
                      <el-table-column prop="api_id"
                                       label="前置接口">
                        <template slot-scope="scope">

                          <el-select v-model="scope.row.api_id"
                                     style="width: 100%;"
                                     placeholder="请选择前置接口">
                            <el-option v-for="list_info in apiList"
                                       :key="list_info.id"
                                       :label="list_info.id + '：' + list_info.title"
                                       :value=list_info.id></el-option>

                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column prop="operate"
                                       label="操作"
                                       width="80">
                        <template slot-scope="scope">
                          <el-button size="mini"
                                     type="danger"
                                     icon="el-icon-delete"
                                     @click="deleteRow(scope.$index, 'pre_data')">
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div>
                      <el-button type="primary"
                                 icon="el-icon-plus"
                                 size="mini"
                                 style="width: 100%"
                                 @click="addRow('pre_data')">新增一行
                      </el-button>
                    </div>

                  </el-card>
                </el-tab-pane>
                <el-tab-pane label="后置操作"
                             name="fivth">
                  <div style="margin-bottom: 10px; height: 28px">后置操作</div>
                  <el-card style="height: 340px;">
                    <div class="center-grid"
                         style="margin-bottom: 20px;">
                      <el-button type="primary"
                                 icon="el-icon-plus"
                                 size="mini"
                                 style="width: 100%"
                                 @click="addPanel">提取变量
                      </el-button>
                    </div>

                    <div v-for="(pos, index) in post_data"
                         :key="index">
                      <el-collapse v-model="activeNames"
                                   accordion>
                        <el-collapse-item :name="index"
                                          title="提取变量">
                          <el-button type="danger"
                                     icon="el-icon-delete"
                                     circle
                                     size="mini"
                                     style="margin-left: 95%;"
                                     @click="deletePanel(index)"></el-button>
                          <div class="center-grid">
                            <el-form :model="pos"
                                     size="mini"
                                     inline
                                     :rules="rules"
                                     ref="globalForm"
                                     label-width="100px"
                                     class="demo-apiForm">
                              <el-form-item label="变量类型"
                                            prop="global_type">
                                <el-select v-model="pos.global_type"
                                           placeholder="请选择变量类型">
                                  <el-option label="全局变量"
                                             value="all"></el-option>

                                </el-select>
                              </el-form-item>
                              <el-form-item label="变量名称"
                                            prop="global_name">
                                <el-input v-model="pos.global_name"></el-input>
                              </el-form-item>

                              <el-form-item label="提取来源"
                                            prop="res_type">
                                <el-select v-model="pos.res_type"
                                           placeholder="提取来源">
                                  <el-option label="Response JSON"
                                             value="json"></el-option>

                                </el-select>
                              </el-form-item>

                              <el-form-item label="提取路径"
                                            prop="global_path">
                                <el-input v-model="pos.global_path"
                                          placeholder="如：$.data.book[0].title"></el-input>
                              </el-form-item>

                            </el-form>

                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </div>
                  </el-card>
                </el-tab-pane>
                <!-- <el-tab-pane label="后置操作"
                     name="fiveth">后置操作</el-tab-pane> -->
                <!-- <el-tab-pane label="断言"
                         name="sixth">
              <div style="margin-bottom: 10px; height: 28px">前置操作</div>
            </el-tab-pane> -->
              </el-tabs>
            </el-form>

          </el-col>
          <el-col :span="8">

            <el-card style="height: 340px;margin-top: 92px;">
              <div style="margin-bottom: 10px; height: 28px">响应结果</div>
              <div class="scroll-container">
                <json-viewer :value="res_json"
                             :expand-depth="5"
                             copyable
                             sort></json-viewer>

              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
    </el-card>

  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
export default {
  name: "apiDetail",
  components: {
    JsonViewer
  },

  data () {
    return {
      rules: {
        method: [
          { required: true, message: '请选择请求方式', trigger: 'change' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        api_path: [
          // { required: true, message: '请选择活动区域', trigger: 'change' }
          { required: true, message: '请输入接口地址', trigger: 'blur' }
        ],

      },
      data_tpye_list: [
        { "key": "string", "value": "String" },
        { "key": "int", "value": "Int" }
      ],
      radio: 1,
      radio_name: 1,
      api_info: {},
      text_show: false,
      none_show: false,
      apiInfo: null,
      globalForm: {
        global_name: '',
        global_type: '',
        res_type: '',
        global_path: '',

      },
      res_json: "请发送请求", //响应结果
      headers_data: [],
      params_data: [],
      body_data: [],
      pre_data: [],
      post_data: [],
      json_data: '',
      apiList: [],
      apiForm: {
        method: '',
        api_path: '',
        env_name: '',
        obj: '',

      },
      methodList: [
        {
          "key": "get", "value": "Get"
        },
        {
          "key": "post", "value": "Post"
        }
      ],
      activeNames: ['0'],
      activeName: "first",
    }
  },
  created () {
    this.getAPiInfo()


  },
  methods: {
    // 监听params，body， 前置条件 tab点击
    tabClick (event) {

      console.log(event.label)

    },
    //增加行数据
    addRow (data) {
      if (data == 'pre_data') {
        if (this.pre_data == undefined) {
          this.pre_data = new Array();
        }
        let obj = {};

        this.pre_data.push(obj);
      }
      if (data == 'headers_data') {
        if (this.headers_data == undefined) {
          this.headers_data = new Array();
        }
        let obj = {};

        this.headers_data.push(obj);
      }
      if (data == 'params_data') {
        if (this.params_data == undefined) {
          this.params_data = new Array();
        }
        let obj = {};

        this.params_data.push(obj);
      }
      if (data == 'body_data') {
        if (this.body_data == undefined) {
          this.body_data = new Array();
        }
        let obj = {};

        this.body_data.push(obj);
      }

    },

    //删除行数据
    deleteRow (index, data) {
      if (data == "pre_data") {
        this.pre_data.splice(index, 1);
      }
      if (data == 'headers_data') {
        this.headers_data.splice(index, 1);
      }
      if (data == 'params_data') {
        this.params_data.splice(index, 1);
      }
      if (data == 'body_data') {
        this.body_data.splice(index, 1);
      }

    },
    async onSubmit () {

      this.apiInfo.response = this.res_json
      this.apiInfo.obj_id
      console.log(this.apiInfo)
      const { data: res } = await this.$http.post(
        "py/sever/autoApi/saveApi",
        this.apiInfo
      );
      if (res.success == true) {
        if (this.apiInfo.id == '') {
          this.$store.commit('changeCommit', true)
        }
        this.$message.success(res.msg);

        // this.$router.go(0);
      } else {
        this.$message.error(res.msg);
      }
    },
    async getApiList () {
      const { data: res } = await this.$http.get(
        "py/server/autoApi/getApiList?obj_id=" + this.obj_id
      );
      if (res.success) {
        this.apiList = res.data;
        console.log(this.apiList)
      } else {
        this.$message.error(res.msg);
      }
    },
    addPanel () {
      let obj = {};

      this.post_data.push(obj); // 添加一个新的空对象到折叠面板数组
    },
    deletePanel (index) {

      this.post_data.splice(index, 1);
    },
    radio_change (lebal) {
      // 监听radio的接口，当radio选中None，radio=1， 选中Form-data，radio=2， 选中Json，radio=3
      this.radio_name = lebal;
      if (this.radio_name == "1") {
        this.none_show = true;
        this.table_show = false;

        this.text_show = false;
      } else if (this.radio_name == "2") {
        this.table_show = true;
        this.text_show = false;

        this.none_show = false;
        this.params_data = this.apiInfo.body_data;

      } else {
        this.table_show = false;

        this.text_show = true;
        this.none_show = false;

        // this.params_data = this.apiInfo.json_data
      }
    },
    async sendApiRequest () {

      this.apiForm.params_data = this.params_data
      this.apiForm.body_data = this.body_data
      this.apiForm.json_data = this.json_data
      this.apiForm.headers_data = this.headers_data
      this.apiForm.pre_data = this.pre_data
      this.apiForm.post_data = this.post_data
      this.apiForm.body_type = this.radio_name //None，radio=1， 选中Form-data，radio=2， 选中Json，radio=3

      const { data: res } = await this.$http.post("py/sever/autoApi/sendRquest", this.apiForm)
      this.res_json = res

      this.$message.success('请求成功')


    },
    getAPiInfo () {
      this.api_info = JSON.parse(window.sessionStorage.getItem("api_data"))

      this.apiForm = {
        method: this.api_info.method,
        api_path: this.api_info.api_path,
        env_name: this.api_info.env_name,
        obj: this.api_info.obj
      }

      console.log(this.apiForm)
      this.params_data = this.api_info.params_data,
        this.body_data = this.api_info.body_data,
        this.json_data = this.api_info.json_data,
        this.headers_data = this.api_info.headers_data,
        this.pre_data = this.api_info.pre_data,
        this.post_data = this.api_info.post_data,
        this.radio_name = this.api_info.data_type
    },
    // json字符串格式化方法
    formatJson (jsonString) {
      try {
        const jsonObj = JSON.parse(jsonString);
        return JSON.stringify(jsonObj, null, 2);
      } catch (error) {
        return jsonString;
      }
    },
    input_json (x) {
      // 声明创建对象
      try {
        const jsonObj = JSON.parse(this.json_data);
        this.json_data = JSON.stringify(jsonObj, null, 2);
      } catch (e) {
        this.$message.error("输入数据不为Json格式")
      }

    },
    async saveApiInfo () {
      this.apiForm.params_data = this.params_data
      this.apiForm.body_data = this.body_data
      this.apiForm.json_data = this.json_data
      this.apiForm.headers_data = this.headers_data
      this.apiForm.pre_data = this.pre_data
      this.apiForm.post_data = this.post_data
      this.apiForm.body_type = this.radio_name //None，radio=1， 选中Form-data，radio=2， 选中Json，radio=3
      const { data: res } = await this.$http.post(
        "py/sever/autoApi/saveApi", this.apiForm)
      console.log(res)

    },
  }
}
</script>

<style>
.scroll-container {
  overflow: auto;
  height: 300px; /* 根据需要设置高度 */
}
</style>