<template>
  <div>
    <p :style="{ color: 'red' }">*注意：1、注册Leads 只需要输入4个带 * 必填项即可
      2、注册 Client 需要输入账户类型，平台，币种
      3、affid 为IB account num，选填
      4、选完国家后注册的监管要看 apollo配置</p>

    <el-form ref="form"
             :model="form"
             :inline="true"
             :rules="rules">
      <el-form-item label="测试环境"
                    prop="environment"
                    class="custom-label"
                    style="max-width: 500px">
        <el-autocomplete v-model="form.environment"
                         :fetch-suggestions="querySearchAsync"
                         placeholder="注：不填默认core环境"
                         @select="handleSelect"
                         clearable></el-autocomplete>
      </el-form-item>

      <el-form-item label="自定义标识"
                    prop="keyWords"
                    class="custom-label"
                    style="max-width: 500px">
        <el-input v-model="form.keyWords"
                  placeholder="可自定义name标识"
                  clearable />
      </el-form-item>

      <div :style="{ whiteSpace: 'pre-line' }"></div>

      <el-form-item label="注册类型"
                    prop="registerType">
        <el-select v-model="form.registerType"
                   placeholder="请选择注册类型"
                   style="width: 150px">
          <el-option label="Leads"
                     value="1"></el-option>
          <el-option label="Client"
                     value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="品牌"
                    prop="brand">
        <el-select v-model="form.brand"
                   placeholder="请输入品牌"
                   style="width: 150px"
                   @change="onBrandChange">
          <el-option v-for="brand in brandArr"
                     :key="brand"
                     :lable="brand"
                     :value="brand"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="监管"
                    prop="regulator">
        <el-select v-model="form.regulator"
                   placeholder="请输入监管"
                   style="width: 150px">
          <el-option v-for="regulator in regulatorArr"
                     :key="regulator"
                     :label="regulator"
                     :value="regulator"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="国家"
                    prop="country"
                    style="max-width: 500px">
        <el-autocomplete v-model="form.country"
                         :fetch-suggestions="querySearchCountry"
                         placeholder="请输入国家"
                         @select="handleSelect"
                         clearable></el-autocomplete>
      </el-form-item>

      <div :style="{ whiteSpace: 'pre-line' }"></div>

      <el-form-item label="交易平台"
                    prop="platform">
        <el-select v-model="form.platform"
                   placeholder="请输入交易平台"
                   style="width: 150px">
          <el-option label="mt4"
                     value="mt4" />
          <el-option label="mt5"
                     value="mt5" />
        </el-select>
      </el-form-item>
      <el-form-item label="账户类型"
                    prop="accountType">
        <el-select v-model="form.accountType"
                   placeholder="请输入账户类型"
                   style="width: 150px">
          <el-option v-for="item in accountTypeOptions"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="币种"
                    prop="currency">
        <el-select v-model="form.currency"
                   placeholder="请输入币种"
                   style="width: 150px">
          <el-option v-for="item in currencyOptions"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item label="是否上传id&poa"
                    prop="isUploadIdpoa">
        <el-select v-model="form.isUploadIdpoa"
                   placeholder="不选默认上传"
                   style="width: 150px">
          <el-option label="是"
                     value="Y" />
          <el-option label="否"
                     value="N" />
        </el-select>
      </el-form-item>

      <div :style="{ whiteSpace: 'pre-line' , color: 'blue'}">
        --其他参数--<br>
        <br>
      </div>

      <el-form-item label="affid"
                    prop="affid">
        <el-input v-model="form.affid"
                  placeholder="非必填，如有需要请输入"
                  clearable />
      </el-form-item>

      <el-form-item label="cxd"
                    prop="cxd">
        <el-input v-model="form.cxd"
                  placeholder="非必填，如有需要请输入"
                  clearable />
      </el-form-item>
      <el-form-item label="ls"
                    prop="ls">
        <el-input v-model="form.ls"
                  placeholder="非必填，如有需要请输入"
                  clearable />
      </el-form-item>
      <div :style="{ whiteSpace: 'pre-line' }"></div>

      <el-form-item label="是否需要批量开户">
        <el-radio-group v-model="selectedOption"
                        @change="handleRadioChange">
          <el-radio label="否">否</el-radio>
          <el-radio label="是">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="selectedOption === '是'">
        <input type="number"
               v-model.number="form.openAccNum"
               placeholder="最大是10"
               :min="1"
               :max="10"
               style="width: 80px">
      </el-form-item>

    </el-form>

    <el-button type="primary"
               :loading="isLoading"
               icon="el-icon-edit-outline"
               @click="submitForm('form')">一键开户</el-button>
    <el-button icon="el-icon-delete"
               @click="resetForm('form')">重置</el-button>

    <div :style="{ whiteSpace: 'pre-line', fontWeight: 'bold' }">
      <br>
      生成的开户数据是:<br>
      <br>
    </div>

    <el-table :data="accountData"
              style="width: 100%"
              border>
      <el-table-column label="序号"
                       type="index"
                       width="80" />
      <el-table-column property="email"
                       label="Email"
                       width="200"></el-table-column>
      <el-table-column property="password"
                       label="Password"
                       width="200"></el-table-column>
      <el-table-column property="userId"
                       label="UserId"
                       width="200"></el-table-column>
      <el-table-column property="fullName"
                       label="FullName"></el-table-column>
      <el-table-column property="result"
                       label="其他"
                       class-name="fixed-height"></el-table-column>
    </el-table>

    <el-collapse v-model="activeNames"
                 @change="handleChange">
      <br>
      <el-collapse-item title="接口返回结果参考"
                        name="1">
        <div>{{ result }}</div>
      </el-collapse-item>
    </el-collapse>

    <!-- <el-descriptions title="生成的开户数据是：" :column="4" class="custom-descriptions" border> -->
    <!-- <el-descriptions-item label="Email" :contentStyle="{ width: '200px' }">{{ result.data.email
        }}</el-descriptions-item>
      <el-descriptions-item label="密码" :contentStyle="{ width: '200px' }">1234Qwer!</el-descriptions-item>
      <el-descriptions-item label="userId" :contentStyle="{ width: '200px' }">{{ result.data.userId
        }}</el-descriptions-item>
      <el-descriptions-item label="fullName" :contentStyle="{ width: '200px' }">{{ result.data.fullName
        }}</el-descriptions-item> -->
    <!-- <el-descriptions-item label="接口返回结果：">{{ result
        }}</el-descriptions-item>
    </el-descriptions> -->

  </div>
</template>

<script>
import { Autocomplete } from 'element-ui';
import envObj from '../../../public/env.json';
import regulatorObj from '../../../public/regulator.json';

export default {
  components: {
    'el-autocomplete': Autocomplete
  },

  data () {
    // 品牌
    const brandArr = Object.keys(regulatorObj)
    const envKey = Object.keys(envObj);
    const envArr = envObj[envKey].map(item => ({ value: item }))
    console.log(envArr)

    return {
      activeNames: ['0'],
      isLoading: false,
      countriesArr: [],
      selectedOption: "否",
      accountData: [],
      form: {
        registerType: '',
        brand: '',
        regulator: '',
        environment: '',
        country: '',
        platform: 'mt5',
        accountType: 'standardSTP',
        currency: 'USD',
        affid: '',
        isUploadIdpoa: 'Y',
        keyWords: '',
        openAccNum: '1',
        cxd: '',
        ls: ''
      },
      currencyOptions: [{ label: 'USD', value: 'USD' },
      { label: 'GBP', value: 'GBP' },
      { label: 'CAD', value: 'CAD' },
      { label: 'AUD', value: 'AUD' },
      { label: 'EUR', value: 'EUR' },
      { label: 'SGD', value: 'SGD' },
      { label: 'NZD', value: 'NZD' },
      { label: 'HKD', value: 'HKD' },
      { label: 'JPY', value: 'JPY' },
      { label: 'USC', value: 'USC' },
      { label: 'BTC', value: 'BTC' },
      { label: 'ETH', value: 'ETH' }
      ],

      registerTypeOptions: [
        { label: 'Leads', value: '1' },
        { label: 'Client', value: '2' }
      ],

      accountTypeOptions: [
        { label: 'Standard STP', value: 'standardSTP' },
        { label: 'Raw ECN', value: 'rawECN' },
        { label: 'Swap free STP', value: 'swapFreeStandardSTP' },
        { label: 'Swap free ECN', value: 'swapFreeRawECN' },
        { label: 'Standard STP Cent', value: 'STANDARD_STP_CENT' },
        { label: 'Swap free STP Cent', value: 'ISLAMIC_STP_CENT' },
        { label: 'Raw ECN Cen', value: 'RAW_ECN_CENT' },
        { label: 'Swap free ECN Cent', value: 'ISLAMIC_ECN_CENT' },
        { label: 'Perpetual', value: 'Perpetual' },
        { label: 'Raw Premium', value: 'rawPREMIUM' }
      ],

      envArr,
      brandArr,
      regulatorObj,
      regulatorArr: [],
      restaurants: [],
      countryLists: [],
      rules: {
        registerType: [
          { required: true, message: '请输入注册类型', trigger: 'blur' }
        ],
        brand: [
          {
            required: true,
            message: 'Please input brand',
            trigger: 'blur'
          }
        ],
        regulator: [
          {
            required: true,
            message: 'Please input regulator',
            trigger: 'blur'
          }
        ],
        country: [
          {
            required: true,
            message: 'Please input country',
            trigger: 'change'
          }
        ]
      },
      result: {}

    }
  },
  methods: {
    handleChange (val) {
      console.log(val);
    },

    async getCountriesArr () {
      const { data: res } = await this.$http.post('api/getCountryList', '')
      this.countriesArr = res.data.countryList
      console.log("国家是：" + res)
    },

    submitForm (formName) {
      this.isLoading = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$http.post(
            'api/openNewAccount',
            this.form
          )
          this.result = res.data
          this.accountData = res.data.data
          console.log("返回值是：" + this.accountData)
          this.isLoading = false;
          this.$message({
            message: '开户请求成功！',
            duration: 1500,
            type: 'success'
          });
        } else {
          console.log('error submit!!');
          this.isLoading = false;
          this.$message({
            message: '开户请求失败，请重试！',
            duration: 1500,
            type: 'error'
          });
          return false;
        }
      });
    },

    open () {
      this.$alert(this.result, '接口返回结果', {
        confirmButtonText: '确定',
        callback: action => {
        }
      });
    },

    handleRadioChange () {
      if (this.selectedOption === '否') {
        this.form.openAccNum = null; // 清空输入框内容
      }
    },

    onBrandChange () {
      this.regulatorArr = this.regulatorObj[this.form.brand] || [];
      this.form.regulator = ''; // 清空已选中的型号
    },

    querySearchAsync (queryString, cb) {
      var restaurants = this.envArr;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      cb(results);
    },
    querySearchCountry (queryString, cb) {
      var countryLists = this.countriesArr.map(item => ({ value: item }));
      var results = queryString ? countryLists.filter(this.createStateFilter(queryString)) : countryLists;
      cb(results);
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect (item) {
      console.log(item);
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.result = {};
      this.accountData = []
    }

  },
  mounted () {
    this.getCountriesArr();
  },
  watch: {
    'form.brand' (newVal, oldVal) {
      console.log(`品牌从 ${oldVal} 变为 ${newVal}`);
      this.form.regulator = regulatorObj[newVal][0];
    }
  }
}
</script>

<style>
.fixed-width-autocomplete {
  width: 200px;
  /* 设置固定宽度 */
}

.custom-label .el-form-item__label {
  font-size: 14px;
  /* 设置字体大小 */
  color: blue;
  /* 设置字体颜色 */
  font-family: 'Mi', sans-serif;
  /* 设置字体类型 */
}

.custom-descriptions .el-descriptions-item__label {
  font-size: 14px;
  /* 设置标签字体大小 */
  font-weight: 400;
  /* 设置标签字体加粗 */
  color: black;
  /* 设置标签字体颜色 */
  width: 120px;
  text-align: left;
}

.fixed-height .el-table__cell {
  height: 50px; /* 设置单元格高度为50px */
  line-height: 50px; /* 设置行高为50px，确保内容垂直居中 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  white-space: nowrap; /* 禁止换行 */
}
</style>